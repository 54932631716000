import React, { useState } from 'react';
import { Paper, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/axios';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ success: false, message: '' });
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setStatus({ success: false, message: '' });

    try {
      const response = await axiosInstance.post('/api/forgot-password', { email });
      setStatus({ success: true, message: response.data.message });
    } catch (error) {
      setError(error.response?.data?.detail || 'An error occurred');
    }
  };

  return (
    <Paper sx={{ p: 4, maxWidth: 400, mx: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        {t('auth.actions.forgotPassword')}
      </Typography>
      
      {status.success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {status.message}
        </Alert>
      )}
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          required
          label={t('auth.fields.email')}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3 }}
        >
          {t('auth.actions.sendResetLink')}
        </Button>
      </Box>
    </Paper>
  );
};

export default ForgotPassword; 