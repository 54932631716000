import React, { useEffect } from 'react';
import { Button } from '@mui/material';

const NewFeatures = () => {
    useEffect(() => {
        const clientWidth = document.body.clientWidth;
        const scriptContent = `
            !function(window){
                const host="https://labs.heygen.com",
                url=host+"/guest/streaming-embed?share=eyJxdWFsaXR5IjoiaGlnaCIsImF2YXRhck5hbWUiOiJTaWxhc0hSX3B1YmxpYyIsInByZXZpZXdJ%0D%0AbWciOiJodHRwczovL2ZpbGVzMi5oZXlnZW4uYWkvYXZhdGFyL3YzLzU4MmVlOGZlMDcyYTQ4ZmRh%0D%0AM2JjNjgyNDFhZWZmNjYwXzQ1NjYwL3ByZXZpZXdfdGFyZ2V0LndlYnAiLCJuZWVkUmVtb3ZlQmFj%0D%0Aa2dyb3VuZCI6ZmFsc2UsImtub3dsZWRnZUJhc2VJZCI6ImMwOWU2NzcyODFjODRkZTFiNTM3YmU4%0D%0AYmNjOTI0MmEyIiwidXNlcm5hbWUiOiIwMGMxOTBlM2EzNzI0NjNmOTZkMzhiODY1OTE0NTVjMyJ9&inIFrame=1",
                wrapDiv=document.createElement("div");
                wrapDiv.id="heygen-streaming-embed";
                const container=document.createElement("div");
                container.id="heygen-streaming-container";
                const stylesheet=document.createElement("style");
                stylesheet.innerHTML=\`
                  #heygen-streaming-embed {
                    z-index: 9999;
                    position: fixed;
                    left: 40px;
                    bottom: 40px;
                    width: 200px;
                    height: 200px;
                    border-radius: 50%;
                    border: 2px solid #fff;
                    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
                    transition: all linear 0.1s;
                    overflow: hidden;

                    opacity: 0;
                    visibility: hidden;
                  }
                  #heygen-streaming-embed.show {
                    opacity: 1;
                    visibility: visible;
                  }
                  #heygen-streaming-embed.expand {
                    ${clientWidth < 540 ? "height: 266px; width: 96%; left: 50%; transform: translateX(-50%);" : "height: 366px; width: calc(366px * 16 / 9);"}
                    border: 0;
                    border-radius: 8px;
                  }
                  #heygen-streaming-container {
                    width: 100%;
                    height: 100%;
                  }
                  #heygen-streaming-container iframe {
                    width: 100%;
                    height: 100%;
                    border: 0;
                  }
                \`;
                const iframe=document.createElement("iframe");
                iframe.allowFullscreen=!1,iframe.title="Streaming Embed",iframe.role="dialog",iframe.allow="microphone",iframe.src=url;
                let visible=!1,initial=!1;
                window.addEventListener("message",(e=>{
                    e.origin===host&&e.data&&e.data.type&&"streaming-embed"===e.data.type&&("init"===e.data.action?(initial=!0,wrapDiv.classList.toggle("show",initial)):"show"===e.data.action?(visible=!0,wrapDiv.classList.toggle("expand",visible)):"hide"===e.data.action&&(visible=!1,wrapDiv.classList.toggle("expand",visible))
                }));
                container.appendChild(iframe);
                wrapDiv.appendChild(stylesheet);
                wrapDiv.appendChild(container);
                document.body.appendChild(wrapDiv);
            }(globalThis);
        `;

        const script = document.createElement('script');
        script.innerHTML = scriptContent;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div style={{ fontFamily: 'Arial, sans-serif', margin: 0, padding: 0, backgroundColor: '#f4f4f4' }}>
            <div style={{ maxWidth: '800px', margin: '50px auto', padding: '20px', backgroundColor: '#fff', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <h1 style={{ textAlign: 'center', color: '#333' }}>Coming New Features</h1>
                <div className="feature" style={{ marginBottom: '30px' }}>
                    <h2 style={{ color: '#555' }}>MimiMeet</h2>
                    <p style={{ color: '#666' }}>Introducing our new AI interviewer avatar, MimiMeet. MimiMeet will assist you in conducting interviews seamlessly.</p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => window.location.href = 'https://labs.heygen.com/interactive-avatar/share?share=eyJxdWFsaXR5IjoiaGlnaCIsImF2YXRhck5hbWUiOiJTaWxhc0hSX3B1YmxpYyIsInByZXZpZXdJ%0D%0AbWciOiJodHRwczovL2ZpbGVzMi5oZXlnZW4uYWkvYXZhdGFyL3YzLzU4MmVlOGZlMDcyYTQ4ZmRh%0D%0AM2JjNjgyNDFhZWZmNjYwXzQ1NjYwL3ByZXZpZXdfdGFyZ2V0LndlYnAiLCJuZWVkUmVtb3ZlQmFj%0D%0Aa2dyb3VuZCI6ZmFsc2UsImtub3dsZWRnZUJhc2VJZCI6ImMwOWU2NzcyODFjODRkZTFiNTM3YmU4%0D%0AYmNjOTI0MmEyIiwidXNlcm5hbWUiOiIwMGMxOTBlM2EzNzI0NjNmOTZkMzhiODY1OTE0NTVjMyJ9'}
                    >
                        Go to Interactive Avatar
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NewFeatures;