import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
  CircularProgress,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  CloudDownload as CloudDownloadIcon,
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';

const ProjectDetail = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [project, setProject] = useState(null);
  const [outline, setOutline] = useState('');
  const [loading, setLoading] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const location = useLocation();
  const { recordAudio: initialRecordAudio, recordVideo: initialRecordVideo } = location.state || { recordAudio: false, recordVideo: false };
  const [recordAudio, setRecordAudio] = useState(initialRecordAudio);
  const [recordVideo, setRecordVideo] = useState(initialRecordVideo);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdatingPreferences, setIsUpdatingPreferences] = useState(false);
  const [interviewType, setInterviewType] = useState('');
  const [interviewLanguage, setInterviewLanguage] = useState('');
  const [maxDuration, setMaxDuration] = useState(60);

  useEffect(() => {
    fetchProjectDetail();
    fetchParticipants();
  }, [uuid]);

  useEffect(() => {
    console.log('Current interview settings:', { interviewType, interviewLanguage });
  }, [interviewType, interviewLanguage]);

  const fetchProjectDetail = async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Current token:', token);

      const response = await axios.get(`/api/projects/uuid/${uuid}`);
      console.log('Project details:', response.data);

      setProject(response.data);
      setOutline(response.data.outline);
      setRecordAudio(response.data.record_audio);

      const duration = response.data.max_duration;
      console.log('Received max_duration:', duration, typeof duration);

      if (typeof duration === 'number' && duration > 0) {
        setMaxDuration(duration);
        console.log('Setting max_duration to:', duration);
      } else {
        console.log('Using default max_duration: 60');
        setMaxDuration(60);
      }

      setInterviewType(response.data.interview_type || '');
      setInterviewLanguage(response.data.interview_language || '');

      console.log('Current interview settings:', { interviewType, interviewLanguage });

      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch project detail:', error);
      setLoading(false);
      setSnackbar({ open: true, message: t('projectDetail.messages.fetchError') });
    }
  };

  const fetchParticipants = async () => {
    try {
      const response = await axios.get(`/api/projects/uuid/${uuid}/participants`);
      console.log('Participants data fetched from API:', response.data);
      setParticipants(response.data);
    } catch (error) {
      console.error('Failed to fetch participants:', {
        error,
        status: error.response?.status,
        data: error.response?.data,
        url: error.config?.url
      });
      setSnackbar({ 
        open: true, 
        message: `Failed to fetch participants: ${error.response?.data?.detail || error.message}`
      });
    }
  };

  const handleSaveOutline = async () => {
    try {
      await axios.put(`/api/projects/outline/${uuid}`, { outline });
      setSnackbar({ open: true, message: t('Outline saved') });
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to save outline:', error);
      setSnackbar({ open: true, message: t('Failed to save outline') });
    }
  };

  const handleSaveRecordingPreferences = async () => {
    setIsUpdatingPreferences(true);
    try {
      console.log('Saving preferences with data:', {
        record_audio: recordAudio,
        max_duration: maxDuration
      });
      
      const response = await axios.patch(`/api/projects/${uuid}`, {
        record_audio: recordAudio,
        max_duration: maxDuration
      });
      
      console.log('Save response:', response.data);
      
      if (response.data.max_duration !== undefined) {
        setMaxDuration(response.data.max_duration);
      }
      
      setSnackbar({ open: true, message: t('Recording preferences saved') });
    } catch (error) {
      console.error('Failed to save recording preferences:', error);
      setSnackbar({ open: true, message: t('Failed to save recording preferences') });
    } finally {
      setIsUpdatingPreferences(false);
    }
  };

  const handleDownloadData = async () => {
    try {
      setDownloadProgress(0);
      const response = await axios.get(`/api/projects/export/${uuid}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setDownloadProgress(percentCompleted);
        },
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `project-${uuid}-all-data.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDownloadProgress(0);
    } catch (error) {
      console.error('Failed to download data:', error);
      setSnackbar({ open: true, message: t('Download failed') });
    }
  };

  const handleDownloadParticipantsCSV = async () => {
    try {
      const response = await axios.get(`/api/projects/uuid/${uuid}/participants/csv`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `project-${uuid}-participants.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Failed to download participants CSV:', error);
      setSnackbar({ open: true, message: t('Download failed') });
    }
  };

  const handleDeleteParticipant = async (interviewUuid) => {
    if (window.confirm(t('Are you sure you want to delete this participant? This action cannot be undone.'))) {
      try {
        // Make DELETE request to backend
        await axios.delete(`/api/interviews/${interviewUuid}`);

        // Show success message
        setSnackbar({ open: true, message: t('Succesfully deleted') });

        // Re-fetch participants to update the list
        fetchParticipants();
      } catch (error) {
        console.error('Not possible to delete a single interview:', error);
        setSnackbar({
          open: true,
          message: error.response?.data?.detail || t('Fail to delete participant')
        });
      }
    }
  };

  const handleSaveInterviewSettings = async () => {
    try {
      console.log('Saving settings:', { interviewType, interviewLanguage });
      await axios.put(`/api/uuid/${uuid}/interview-settings`, {
        interview_type: interviewType,
        interview_language: interviewLanguage
      });
      console.log('Settings saved successfully');
      setSnackbar({ open: true, message: t('projectDetail.messages.settingsSaved') });
      // Refresh to get the latest database values
      await fetchProjectDetail();
      console.log('Settings after refresh:', { 
        type: project.interview_type, 
        language: project.interview_language 
      });
    } catch (error) {
      console.error('Failed to save interview settings:', error);
      setSnackbar({ open: true, message: t('projectDetail.messages.settingsError') });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            {project.name}
          </Typography>

          {/* Interview Outline Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">{t('projectDetail.sections.outline')}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="body2" color="textSecondary">
                {t('projectDetail.sections.outlineHelp')}
              </Typography>
              <Button
                component={Link}
                to="/guide"
                size="small"
                sx={{ ml: 1 }}
              >
                View Interview Guide
              </Button>
            </Box>
            <TextField
              value={outline}
              onChange={(e) => setOutline(e.target.value)}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              margin="normal"
              disabled={!isEditing}
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              {isEditing ? (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveOutline}
                >
                  {t('projectDetail.actions.saveOutline')}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => setIsEditing(true)}
                >
                  {t('common.buttons.edit')}
                </Button>
              )}
            </Box>
          </Box>

          {/* Data Management Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">{t('projectDetail.sections.data')}</Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<CloudDownloadIcon />}
                  onClick={handleDownloadData}
                >
                  {t('projectDetail.actions.downloadData')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<CloudDownloadIcon />}
                  onClick={handleDownloadParticipantsCSV}
                >
                  {t('projectDetail.actions.downloadCsv')}
                </Button>
              </Grid>
            </Grid>
            {downloadProgress > 0 && (
              <Box sx={{ mt: 2 }}>
                <LinearProgress variant="determinate" value={downloadProgress} />
              </Box>
            )}
          </Box>

          {/* Participants Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">{t('projectDetail.sections.participants')}</Typography>
            {participants.length > 0 ? (
              <Table sx={{ mt: 2 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('interview.fields.name')}</TableCell>
                    <TableCell>{t('interview.fields.age')}</TableCell>
                    <TableCell>{t('interview.fields.gender')}</TableCell>
                    <TableCell>{t('interview.fields.occupation')}</TableCell>
                    <TableCell>{t('interview.fields.education')}</TableCell>
                    <TableCell>{t('common.buttons.delete')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {participants.map((participant) => (
                    <TableRow key={participant.interview_uuid}>
                      <TableCell>{participant.interviewee_name}</TableCell>
                      <TableCell>{participant.interviewee_age}</TableCell>
                      <TableCell>{participant.interviewee_gender}</TableCell>
                      <TableCell>{participant.interviewee_occupation}</TableCell>
                      <TableCell>{participant.interviewee_education}</TableCell>
                      <TableCell>
                        <Tooltip title={t('projectDetail.actions.deleteParticipant')}>
                          <IconButton
                            color="secondary"
                            onClick={() => handleDeleteParticipant(participant.interview_uuid)}
                            aria-label={t('projectDetail.actions.deleteParticipant')}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Typography variant="body1" sx={{ mt: 2 }}>
                {t('projectDetail.messages.noParticipants')}
              </Typography>
            )}
          </Box>

          {/* Recording Options Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">{t('projectDetail.sections.recordingOptions')}</Typography>
            <Box sx={{ mt: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={recordAudio}
                    onChange={(e) => setRecordAudio(e.target.checked)}
                  />
                }
                label={t('projectDetail.fields.recordAudio')}
              />
              <Box sx={{ width: 300, mt: 3 }}>
                <Typography gutterBottom>
                  {t('projectDetail.fields.maxDuration')}: {maxDuration} {t('common.minutes')}
                </Typography>
                <Slider
                  value={maxDuration}
                  onChange={(e, newValue) => setMaxDuration(newValue)}
                  min={1}
                  max={120}
                  valueLabelDisplay="auto"
                  marks={[
                    { value: 1, label: '1' },
                    { value: 60, label: '60' },
                    { value: 120, label: '120' },
                  ]}
                />
              </Box>
              <Button
                variant="contained"
                onClick={handleSaveRecordingPreferences}
                disabled={isUpdatingPreferences}
                sx={{ ml: 2 }}
              >
                {isUpdatingPreferences ? (
                  <CircularProgress size={24} />
                ) : (
                  t('projectDetail.actions.saveRecordingPreferences')
                )}
              </Button>
            </Box>
          </Box>

          {/* Interview Settings Section */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">{t('projectDetail.sections.interviewSettings')}</Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>{t('projects.fields.interviewType')}</InputLabel>
              <Select
                value={interviewType || ''}
                onChange={(e) => setInterviewType(e.target.value)}
              >
                <MenuItem value="STRUCTURED">{t('projects.interviewTypes.structured')}</MenuItem>
                <MenuItem value="SEMI_STRUCTURED">{t('projects.interviewTypes.semiStructured')}</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel>{t('projects.fields.language')}</InputLabel>
              <Select
                value={interviewLanguage || ''}
                onChange={(e) => setInterviewLanguage(e.target.value)}
              >
                <MenuItem value="ENGLISH">{t('projects.languages.english')}</MenuItem>
                <MenuItem value="CHINESE">{t('projects.languages.chinese')}</MenuItem>
                <MenuItem value="FRENCH">{t('projects.languages.french')}</MenuItem>
                <MenuItem value="NORWEGIAN">{t('projects.languages.norwegian')}</MenuItem>
                <MenuItem value="FLEXIBLE">{t('projects.languages.flexible')}</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              onClick={handleSaveInterviewSettings}
              sx={{ mt: 2 }}
            >
              {t('projectDetail.actions.saveInterviewSettings')}
            </Button>
          </Box>
        </Paper>
      )}
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </Container>
  );
};

export default ProjectDetail;
