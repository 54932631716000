import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Snackbar,
  FormControlLabel,
  Checkbox,
  Box,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider
} from '@mui/material';
import { 
  Add as AddIcon, 
  ContentCopy as ContentCopyIcon, 
  Delete as DeleteIcon, 
  Download as DownloadIcon,
  QuestionAnswer as QuestionAnswerIcon 
} from '@mui/icons-material';
import axiosInstance from '../utils/axios';
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [recordAudio, setRecordAudio] = useState(false);
  const [recordVideo, setRecordVideo] = useState(false);
  const [currentProject, setCurrentProject] = useState({
    name: '',
    outline: '',
    recordAudio: true,
    recordVideo: false,
    interviewType: '',
    interviewLanguage: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [maxDuration, setMaxDuration] = useState(60);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axiosInstance.get('/api/projects');
      setProjects(response.data.map(project => ({
        ...project,
        project_uuid: project.project_uuid || project.uuid,
        record_audio: project.record_audio ?? false,
        record_video: project.record_video ?? false
      })));
    } catch (error) {
      setError(error.response?.data?.detail || 'Failed to fetch projects');
      if (error.response?.status === 401) {
        // If token is invalid, redirect to login
        localStorage.removeItem('token');
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [navigate]);

  const handleSubmit = async () => {
    console.log('Submitting project with settings:', {
      name: currentProject.name,
      outline: currentProject.outline,
      interview_type: currentProject.interviewType,
      interview_language: currentProject.interviewLanguage
    });

    if (!currentProject.name || !currentProject.outline || !currentProject.interviewType || !currentProject.interviewLanguage) {
      setSnackbar({ open: true, message: t('projects.fillAllFields') });
      return;
    }

    try {
      const response = await axiosInstance.post('/api/projects', {
        name: currentProject.name,
        outline: currentProject.outline,
        check_duplicate: true,
        record_audio: currentProject.recordAudio,
        record_video: currentProject.recordVideo,
        interview_type: currentProject.interviewType,
        interview_language: currentProject.interviewLanguage,
        max_duration: maxDuration
      });
      console.log('Project creation response:', response.data);

      await fetchProjects();
      setSnackbar({ open: true, message: t('projects.saveSuccess') });
      setOpenDialog(false);
      setCurrentProject({ name: '', outline: '' });
    } catch (error) {
      console.error('Failed to save project:', error.response?.data || error);
      setSnackbar({
        open: true,
        message: error.response?.data?.detail || t('projects.saveFailed')
      });
    }
  };


  const handleCopyLink = (shareUrl) => {
    navigator.clipboard.writeText(`${window.location.origin}/interview/${shareUrl}`);
    setSnackbar({ open: true, message: t('projects.linkCopied') });
  };

  const handleDelete = async (projectId) => {
    if (window.confirm(t('projects.confirmDelete'))) {
      try {
        await axiosInstance.delete(`/api/projects/${projectId}`);
        await fetchProjects();
        setSnackbar({ open: true, message: t('Project delete success') });
      } catch (error) {
        console.error('Failed to delete project:', error);
        setSnackbar({ open: true, message: t('projects.deleteFailed') });
      }
    }
  };

  const handleDownload = async (uuid) => {
    try {
      setDownloadProgress(0);
      const response = await axiosInstance.get(`/api/projects/export/${uuid}`, {
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setDownloadProgress(percentCompleted);
        }
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `project-${uuid}-all-data.zip`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setDownloadProgress(0);
      setSnackbar({ open: true, message: t('projects.downloadSuccess') });
    } catch (error) {
      console.error('Failed to download:', error);
      setSnackbar({ open: true, message: t('projects.downloadFailed') });
    }
  };

  const handleStartInterview = (project) => {
    navigate(`/interview/${project.id}`, {
      state: { 
        recordAudio: project.record_audio ?? false,
        recordVideo: project.record_video ?? false
      },
    });
  };

  const handleDialogOpen = () => {
    setCurrentProject(prev => ({
      ...prev,
      interviewType: 'SEMI_STRUCTURED',
      interviewLanguage: 'ENGLISH'
    }));
    setOpenDialog(true);
  };

  return (
    <Container>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{t('projects.title')}</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setCurrentProject({ name: '', outline: '', api_key: '' });
              setRecordAudio(false);  // Reset to defaults
              setRecordVideo(false);
              setOpenDialog(true);
            }}
          >
            {t('projects.actions.create')}
          </Button>
        </Grid>

        {loading && (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        )}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {projects.map((project) => (
          <Grid item xs={12} sm={6} md={4} key={project.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>{project.name}</Typography>
                <Typography variant="body2" color="text.secondary" noWrap>
                  {project.outline}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => navigate(`/projects/${project.project_uuid || project.uuid}`)}
                >
                  {t('common.buttons.view')}
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setCurrentProject(project);
                    setRecordAudio(project.record_audio ?? false);  // Set recording preferences
                    setRecordVideo(project.record_video ?? false);
                    setOpenDialog(true);
                  }}
                >
                  {t('common.buttons.edit')}
                </Button>
                <IconButton
                  size="small"
                  onClick={() => handleCopyLink(project.share_url)}
                  title={t('projects.actions.copyLink')}
                >
                  <ContentCopyIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleDownload(project.project_uuid)}
                  title={t('common.buttons.download')}
                >
                  <DownloadIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => handleDelete(project.id)}
                  title={t('common.buttons.delete')}
                >
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {currentProject.id ? t('projects.actions.edit') : t('projects.actions.create')}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t('projects.fields.name')}
            value={currentProject.name}
            onChange={(e) => setCurrentProject({ ...currentProject, name: e.target.value })}
            margin="normal"
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label={t('projects.fields.outline')}
            value={currentProject.outline}
            onChange={(e) => setCurrentProject({ ...currentProject, outline: e.target.value })}
            margin="normal"
          />
          <Box sx={{ mt: 1, mb: 2 }}>
            <Button
              component={Link}
              to="/guide"
              color="primary"
              size="small"
              startIcon={<QuestionAnswerIcon />}
            >
              {t('projectDetail.sections.outlineHelp')}
            </Button>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={recordAudio}
                onChange={(e) => setRecordAudio(e.target.checked)}
              />
            }
            label={t('projects.fields.recordAudio')}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>{t('projects.fields.interviewType')}</InputLabel>
            <Select
              value={currentProject.interviewType}
              onChange={(e) => setCurrentProject(prev => ({ ...prev, interviewType: e.target.value }))}
            >
              <MenuItem value="STRUCTURED">{t('projects.interviewTypes.structured')}</MenuItem>
              <MenuItem value="SEMI_STRUCTURED">{t('projects.interviewTypes.semiStructured')}</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>{t('projects.fields.language')}</InputLabel>
            <Select
              value={currentProject.interviewLanguage}
              onChange={(e) => setCurrentProject(prev => ({ ...prev, interviewLanguage: e.target.value }))}
            >
              <MenuItem value="ENGLISH">{t('projects.languages.english')}</MenuItem>
              <MenuItem value="CHINESE">{t('projects.languages.chinese')}</MenuItem>
              <MenuItem value="FRENCH">{t('projects.languages.french')}</MenuItem>
              <MenuItem value="NORWEGIAN">{t('projects.languages.norwegian')}</MenuItem>
              <MenuItem value="FLEXIBLE">{t('projects.languages.flexible')}</MenuItem>
            </Select>
          </FormControl>

          <Box sx={{ mt: 3, width: '100%' }}>
            <Typography gutterBottom>
              {t('projectDetail.fields.maxDuration')}: {maxDuration} {t('common.minutes')}
            </Typography>
            <Slider
              value={maxDuration}
              onChange={(e, newValue) => setMaxDuration(newValue)}
              min={1}
              max={120}
              valueLabelDisplay="auto"
              marks={[
                { value: 1, label: '1' },
                { value: 60, label: '60' },
                { value: 120, label: '120' },
              ]}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>
            {t('common.buttons.cancel')}
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            {t('common.buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Container>
  );
};

export default Projects;