import React from 'react';
import { jwtDecode } from 'jwt-decode';
import { Box, Container, AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const MainLayout = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const token = localStorage.getItem('token');
  
  const getUsername = () => {
    if (!token) return null;
    try {
      const decoded = jwtDecode(token);
      return decoded.sub;
    } catch (error) {
      console.error('Invalid token:', error);
      localStorage.removeItem('token');
      return null;
    }
  };

  const username = getUsername();

  React.useEffect(() => {
    if (!token || !username) {
      setAnchorEl(null);
    }
  }, [token, username]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    handleClose();
    navigate('/login');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar position="static">
            <Toolbar>
                <Box
                    component={Link}
                    to="/home"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textDecoration: 'none',
                        color: 'inherit',
                        flexGrow: 1,
                    }}
                >
                    <img
                        src="/logo512.png"
                        alt="Logo"
                        style={{
                            height: '40px',
                            marginRight: '10px'
                        }}
                    />
                    <Typography variant="h6">
                        {t('nav.home')}
                    </Typography>
                </Box>
                
                <Button 
                    color="inherit" 
                    component={Link} 
                    to="/about"
                    sx={{ mr: 2 }}
                >
                    {t('nav.about')}
                </Button>

                <Button 
                    color="inherit" 
                    component={Link} 
                    to="/research"
                    sx={{ mr: 2 }}
                >
                    {t('nav.research')}
                </Button>
                
                {username ? (
                    <>
                        <Button 
                            color="inherit" 
                            component={Link} 
                            to="/projects"
                        >
                            {t('nav.projects')}
                        </Button>
                        <IconButton
                            color="inherit"
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                        >
                            <AccountCircleIcon />
                            <Typography variant="subtitle1" sx={{ ml: 1 }}>
                                {username}
                            </Typography>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={() => setAnchorEl(null)}
                        >
                            <MenuItem onClick={() => {
                                setAnchorEl(null);
                                navigate('/profile');
                            }}>
                                {t('nav.profile')}
                            </MenuItem>
                            <MenuItem onClick={() => {
                                setAnchorEl(null);
                                navigate('/recharge');
                            }}>
                                {t('nav.recharge')}
                            </MenuItem>
                            <MenuItem onClick={() => {
                                localStorage.removeItem('token');
                                localStorage.removeItem('username');
                                setAnchorEl(null);
                                navigate('/login');
                            }}>
                                {t('nav.logout')}
                            </MenuItem>
                        </Menu>
                        <LanguageSwitcher />
                    </>
                ) : (
                    <>
                        <Button color="inherit" component={Link} to="/login">
                            {t('nav.login')}
                        </Button>
                        <Button color="inherit" component={Link} to="/register">
                            {t('nav.register')}
                        </Button>
                        <LanguageSwitcher />
                    </>
                )}
            </Toolbar>
        </AppBar>
        <Container component="main" sx={{ mt: 4, mb: 4 }}>
            {children}
        </Container>
    </Box>
  );
};

export default MainLayout;