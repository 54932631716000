import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Grid, 
  Typography, 
  Card, 
  CardContent, 
  CircularProgress,
  Box
} from '@mui/material';
import {
  People as PeopleIcon,
  Assignment as ProjectIcon,
  QuestionAnswer as InterviewIcon,
  Payment as PaymentIcon,
  Storage as StorageIcon
} from '@mui/icons-material';
import axiosInstance from '../utils/axios';

const StatCard = ({ title, value, icon, subtitle }) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Box display="flex" alignItems="center" mb={2}>
        {icon}
        <Typography variant="h6" component="div" ml={1}>
          {title}
        </Typography>
      </Box>
      <Typography variant="h4" component="div" gutterBottom>
        {value}
      </Typography>
      {subtitle && (
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      )}
    </CardContent>
  </Card>
);

const Admin = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axiosInstance.get('/api/admin/dashboard');
        setStats(response.data);
      } catch (error) {
        setError(error.response?.data?.detail || 'Failed to fetch admin statistics');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>

      <Grid container spacing={3}>
        {/* Users Section */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>User Statistics</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Users"
            value={stats.users.total}
            icon={<PeopleIcon color="primary" />}
            subtitle={`${stats.users.last_24h} new in last 24h`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Verified Users"
            value={stats.users.verification_rate}
            icon={<PeopleIcon color="success" />}
            subtitle={`${stats.users.verified} verified users`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Active Users (7d)"
            value={stats.users.active_7d}
            icon={<PeopleIcon color="info" />}
          />
        </Grid>

        {/* Projects Section */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>Project Statistics</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Projects"
            value={stats.projects.total}
            icon={<ProjectIcon color="primary" />}
            subtitle={`${stats.projects.last_24h} new in last 24h`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Active Projects"
            value={stats.projects.active}
            icon={<ProjectIcon color="success" />}
            subtitle={`${stats.projects.avg_per_user} avg per user`}
          />
        </Grid>

        {/* Interviews Section */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>Interview Statistics</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Interviews"
            value={stats.interviews.total}
            icon={<InterviewIcon color="primary" />}
            subtitle={`${stats.interviews.last_24h} new in last 24h`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Completion Rate"
            value={stats.interviews.completion_rate}
            icon={<InterviewIcon color="success" />}
            subtitle={`${stats.interviews.completed} completed`}
          />
        </Grid>

        {/* Transactions Section */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>Transaction Statistics</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Transactions"
            value={stats.transactions.total_count}
            icon={<PaymentIcon color="primary" />}
            subtitle={`${stats.transactions.last_24h} new in last 24h`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Credits Sold"
            value={stats.transactions.total_credits}
            icon={<PaymentIcon color="success" />}
            subtitle={`Avg ${stats.transactions.avg_transaction} per transaction`}
          />
        </Grid>

        {/* Storage Section */}
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>Storage Statistics</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Storage"
            value={`${stats.storage.total_gb} GB`}
            icon={<StorageIcon color="primary" />}
            subtitle={`${stats.storage.storage_per_user_mb} MB per user`}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Avg Project Size"
            value={`${stats.storage.avg_project_mb} MB`}
            icon={<StorageIcon color="info" />}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Admin; 