import React, { useState } from 'react';
import { Paper, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axios';  // 修改导入
import { useTranslation } from 'react-i18next';

const Register = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({});
  const [registrationStatus, setRegistrationStatus] = useState({
    success: false,
    message: '',
    emailSent: false
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation
    const errors = {};
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email format';
    }
    
    if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = t('auth.messages.passwordMismatch');
      setErrors(errors);
      return;
    }
    
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      const response = await axiosInstance.post('/api/register', {
        username: formData.username,
        password: formData.password,
        email: formData.email
      });

      const data = response.data;

      setRegistrationStatus({
        success: true,
        message: data.message,
        emailSent: data.email_sent
      });
      
      // Only redirect if email wasn't sent (in case of email sending failure)
      if (!data.email_sent) {
        setTimeout(() => navigate('/login'), 5000);
      }
    } catch (error) {
      setErrors({ 
        submit: error.response?.data?.detail || 
                error.response?.data?.message || 
                'Registration failed' 
      });
    }
  };

  return (
    <Paper sx={{ p: 4, maxWidth: 400, mx: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        {t('auth.actions.register')}
      </Typography>
      {registrationStatus.success && (
        <Alert 
          severity={registrationStatus.emailSent ? "success" : "warning"} 
          sx={{ mb: 2 }}
        >
          {registrationStatus.message}
          {registrationStatus.emailSent && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              {t('auth.messages.checkEmail')}
            </Typography>
          )}
        </Alert>
      )}
      {errors.submit && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errors.submit}
        </Alert>
      )}
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          margin="normal"
          label={t('auth.fields.username')}
          name="username"
          value={formData.username}
          onChange={handleChange}
          error={!!errors.username}
          helperText={errors.username}
        />
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          fullWidth
          margin="normal"
          label={t('auth.fields.password')}
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
        />
        <TextField
          fullWidth
          margin="normal"
          label={t('auth.fields.confirmPassword')}
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>
          {t('auth.actions.register')}
        </Button>
      </Box>
    </Paper>
  );
};

export default Register;