import React from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  Box, 
  Card, 
  CardContent, 
  Grid,
  Chip,
  Link,
  Divider
} from '@mui/material';
import { 
  Science as ScienceIcon,
  Psychology as PsychologyIcon,
  Timeline as TimelineIcon,
  School as SchoolIcon,
  Article as ArticleIcon,
  Person as PersonIcon,
  ThumbUp as ThumbUpIcon,
  BarChart as BarChartIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Research = () => {
  const { t } = useTranslation();

  const researchPapers = [
    {
      title: "Step Further Towards Automated Social Science: An AI-Powered Interview Platform",
      authors: "Fengming Liu, Shubin Yu",
      date: "2025",
      description: "This study introduces MimiTalk, an AI-powered automated interview platform capable of conducting in-depth interviews autonomously while maintaining high data quality standards. Through evaluation involving 20 participants, we demonstrate significant improvements in interview efficiency while maintaining response quality comparable to traditional methods. Results show an average interview duration of 141.632 seconds with strong semantic coherence and comprehensive topic coverage.",
      tags: ["AI Interview", "Platform Development", "Evaluation Study"],
      link: "#",
      status: "Preprint submitting"
    },
    {
      title: "The Comparison of AI Interviewers and Human Interviewers: A Large Scale Field Experiment",
      authors: "MimiTalk Research Team",
      date: "2025",
      description: "This study investigates the comparative effectiveness of AI and human interviewers through the development and deployment of two AI interview platforms—MimiTalk (online) and MimiCall (telephone-based). Drawing from Social Desirability Bias Theory, Emotional Contagion Theory, and Information Theory, we examine differences in questioning strategies, response patterns, and information quality.",
      tags: ["Comparative Study", "Field Experiment", "Interview Methods"],
      link: "#",
      status: "Preprint submitting"
    },
    {
      title: "Comparative Analysis of Multi-Modal AI Interview Systems",
      authors: "MimiTalk Research Team",
      date: "2025",
      description: "A systematic comparison of different modalities in AI-driven interview systems, evaluating four distinct configurations: text-to-text, voice-to-text, video-to-text, and voice-to-voice interactions. The study assesses these modalities across key metrics including response quality, participant comfort, information richness, and technical reliability.",
      tags: ["Multi-Modal Analysis", "System Evaluation", "User Experience"],
      link: "#",
      status: "In preparation"
    },
    {
      title: "Qualitative Researchers' Perspectives on AI Interview Platforms",
      authors: "MimiTalk Research Team",
      date: "2025",
      description: "An investigation into how qualitative researchers perceive and evaluate AI-powered interview platforms, examining opportunities and challenges in academic research contexts.",
      tags: ["User Study", "Research Methods", "Professional Perspectives"],
      link: "#",
      status: "Ongoing Research"
    },
    {
      title: "AI Interviewers in Cross-Cultural Research Contexts",
      authors: "MimiTalk Research Team",
      date: "2025",
      description: "Exploring the effectiveness and adaptability of AI interviewers in cross-cultural research settings, examining cultural sensitivity and communication patterns.",
      tags: ["Cross-Cultural Research", "AI Adaptation", "Cultural Sensitivity"],
      link: "#",
      status: "Ongoing Research"
    },
    {
      title: "Applications of AI Interviewers in Educational Research",
      authors: "MimiTalk Research Team",
      date: "2025",
      description: "A conceptual paper examining the potential applications and implications of AI interview systems in educational research and assessment.",
      tags: ["Education", "Conceptual Framework", "Research Applications"],
      link: "#",
      status: "Conceptual Paper"
    },
    {
      title: "Efficacy of Large Language Models in Short-Text Topic Modeling",
      authors: "Shubin Yu",
      date: "2024",
      description: "This study explores the efficacy of large language models (LLMs) in short-text topic modeling, comparing their performance with human evaluation and Latent Dirichlet Allocation (LDA). In Study 1, we analyzed a dataset on chatbot anthropomorphism using human evaluation, LDA, and two LLMs (GPT-4 and Claude). Results showed that LLMs produced topic classifications similar to human analysis, outperforming LDA for short texts. In Study 2, we investigated the impact of sample size and LLM choice on topic modeling consistency using a COVID-19 vaccine hesitancy dataset. Findings revealed high consistency (80-90%) across various sample sizes, with even a 5% sample achieving 90% consistency. Comparison of three LLMs (Gemini Pro 1.5, GPT-4o, and Claude 3.5 Sonnet) showed comparable performance, with two models achieving 90% consistency. This research demonstrates that LLMs can effectively perform short-text topic modeling in medical informatics, offering a promising alternative to traditional methods. The high consistency with small sample sizes suggests potential for improved efficiency in research. However, variations in performance highlight the importance of model selection and the need for human supervision in topic modeling tasks.",
      tags: ["LLM", "Topic Modeling", "Medical Informatics"],
      link: "https://osf.io/preprints/osf/mqk3r",
      status: "Preprint Published"
    }
  ];

  const researchHighlights = [
    {
      title: "User-Friendly Platform",
      description: "Intuitive navigation for both researchers and interviewees, ensuring a smooth interview experience",
      icon: <PersonIcon fontSize="large" color="primary" />
    },
    {
      title: "High User Satisfaction",
      description: "Interviewees report positive experiences with the digital interview format, leading to more authentic responses",
      icon: <ThumbUpIcon fontSize="large" color="primary" />
    },
    {
      title: "Quality Results",
      description: "Interviews yield high semantic coherence, comprehensive insights, and diverse perspectives across participants",
      icon: <BarChartIcon fontSize="large" color="primary" />
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Header Section */}
      <Box sx={{ mb: 4, textAlign: 'center' }}>
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 600, color: 'primary.main' }}>
          {t('research.title')}
        </Typography>
        <Typography variant="h6" color="text.secondary" paragraph>
          {t('research.subtitle')}
        </Typography>
        <Divider sx={{ my: 3 }} />
      </Box>

      {/* Research Highlights Section */}
      <Box sx={{ mb: 6 }}>
        <Typography variant="h4" gutterBottom sx={{ fontWeight: 500, mb: 3, color: 'text.primary' }}>
          {t('research.highlights')}
        </Typography>
        <Grid container spacing={3}>
          {researchHighlights.map((highlight, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card sx={{ 
                height: '100%', 
                boxShadow: 3,
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)'
                }
              }}>
                <CardContent sx={{ p: 3 }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    mb: 2,
                    color: 'primary.main'
                  }}>
                    {highlight.icon}
                  </Box>
                  <Typography 
                    variant="h5" 
                    component="h2" 
                    align="center" 
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    {highlight.title}
                  </Typography>
                  <Typography 
                    variant="body1" 
                    color="text.secondary" 
                    align="center"
                    sx={{ lineHeight: 1.6 }}
                  >
                    {highlight.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Research Projects Section */}
      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ 
            fontWeight: 500, 
            mb: 3,
            color: 'text.primary'
          }}
        >
          {t('research.projects')}
        </Typography>
        <Grid container spacing={3}>
          {researchPapers.map((paper, index) => (
            <Grid item xs={12} key={index}>
              <Paper 
                elevation={3} 
                sx={{ 
                  p: 3,
                  borderRadius: 2,
                  backgroundColor: 'background.paper'
                }}
              >
                <Box sx={{ mb: 2 }}>
                  <Typography 
                    variant="h5" 
                    component="h2" 
                    gutterBottom
                    sx={{ fontWeight: 500 }}
                  >
                    <Link 
                      href={paper.link} 
                      underline="hover" 
                      color="inherit"
                      sx={{
                        '&:hover': {
                          color: 'primary.main'
                        }
                      }}
                    >
                      {paper.title}
                    </Link>
                  </Typography>
                  <Typography 
                    variant="subtitle1" 
                    color="text.secondary" 
                    gutterBottom
                    sx={{ fontStyle: 'italic' }}
                  >
                    {paper.authors} • {paper.date} • {paper.status}
                  </Typography>
                </Box>
                <Typography 
                  variant="body1" 
                  paragraph
                  sx={{ 
                    mb: 2,
                    lineHeight: 1.7,
                    textAlign: 'justify'
                  }}
                >
                  {paper.description}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {paper.tags.map((tag, tagIndex) => (
                    <Chip 
                      key={tagIndex} 
                      label={tag} 
                      size="small" 
                      variant="outlined"
                      sx={{ 
                        borderColor: 'primary.main',
                        color: 'text.primary'
                      }}
                    />
                  ))}
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Footer Section */}
      <Box sx={{ mt: 6, py: 3, borderTop: 1, borderColor: 'divider' }}>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          align="center"
        >
          {t('research.footer')}
        </Typography>
      </Box>
    </Container>
  );
};

export default Research; 