import React from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Card,
  CardContent,
  Button,
  Grid
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  PlayArrow as PlayArrowIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Psychology as PsychologyIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const InterviewGuide = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Button 
          onClick={() => navigate('/projects')}
          variant="contained" 
          color="primary"
          sx={{ mb: 2 }}
        >
          {t('common.buttons.back')}
        </Button>
      </Box>
      <Typography variant="h3" gutterBottom align="center" color="primary">
        {t('interviewGuide.title')}
      </Typography>
      
      {/* Introduction Card */}
      <Card sx={{ mb: 4, bgcolor: 'primary.light', color: 'white' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {t('interviewGuide.subtitle')}
          </Typography>
          <Typography variant="body1">
            {t('interviewGuide.description')}
          </Typography>
        </CardContent>
      </Card>

      {/* Interview Procedures */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PlayArrowIcon sx={{ mr: 2 }} />
            <Typography variant="h6">{t('interviewGuide.procedures.title')}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {/* Opening Section */}
            <Card sx={{ flex: '1 1 300px' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom color="primary">
                  {t('interviewGuide.procedures.opening.title')}
                </Typography>
                <List>
                  {t('interviewGuide.procedures.opening.items', { returnObjects: true }).map((item) => (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>

            {/* Main Section */}
            <Card sx={{ flex: '1 1 300px' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom color="primary">
                  {t('interviewGuide.procedures.main.title')}
                </Typography>
                <List>
                  {t('interviewGuide.procedures.main.items', { returnObjects: true }).map((item) => (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>

            {/* Closing Section */}
            <Card sx={{ flex: '1 1 300px' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom color="primary">
                  {t('interviewGuide.procedures.closing.title')}
                </Typography>
                <List>
                  {t('interviewGuide.procedures.closing.items', { returnObjects: true }).map((item) => (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Questions Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <QuestionAnswerIcon sx={{ mr: 2 }} />
            <Typography variant="h6">{t('interviewGuide.questions.title')}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom color="primary">
              {t('interviewGuide.questions.structure')}
            </Typography>
            <List>
              <ListItem>
                <ListItemIcon><PlayArrowIcon /></ListItemIcon>
                <ListItemText 
                  primary={t('interviewGuide.questions.opening.title')}
                  secondary={t('interviewGuide.questions.opening.examples')}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon><PlayArrowIcon /></ListItemIcon>
                <ListItemText 
                  primary={t('interviewGuide.questions.main.title')}
                  secondary={t('interviewGuide.questions.main.examples')}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon><PlayArrowIcon /></ListItemIcon>
                <ListItemText 
                  primary={t('interviewGuide.questions.followUp.title')}
                  secondary={t('interviewGuide.questions.followUp.examples')}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon><PlayArrowIcon /></ListItemIcon>
                <ListItemText 
                  primary={t('interviewGuide.questions.closing.title')}
                  secondary={t('interviewGuide.questions.closing.examples')}
                />
              </ListItem>
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Techniques Section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PsychologyIcon sx={{ mr: 2 }} />
            <Typography variant="h6">{t('interviewGuide.techniques.title')}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {/* Questioning Techniques */}
            <Card sx={{ flex: '1 1 300px' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom color="primary">
                  {t('interviewGuide.techniques.questioning.title')}
                </Typography>
                <List>
                  {t('interviewGuide.techniques.questioning.items', { returnObjects: true }).map((item) => (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>

            {/* Communication Skills */}
            <Card sx={{ flex: '1 1 300px' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom color="primary">
                  {t('interviewGuide.techniques.communication.title')}
                </Typography>
                <List>
                  {t('interviewGuide.techniques.communication.items', { returnObjects: true }).map((item) => (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>

            {/* Professional Conduct */}
            <Card sx={{ flex: '1 1 300px' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom color="primary">
                  {t('interviewGuide.techniques.conduct.title')}
                </Typography>
                <List>
                  {t('interviewGuide.techniques.conduct.items', { returnObjects: true }).map((item) => (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <CheckCircleIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default InterviewGuide; 