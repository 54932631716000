import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paper, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/axios';

const ResetPassword = () => {
  const { t } = useTranslation();
  const [passwords, setPasswords] = useState({
    newPassword: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (passwords.newPassword !== passwords.confirmPassword) {
      setError(t('auth.messages.passwordMismatch'));
      return;
    }

    const token = new URLSearchParams(location.search).get('token');
    if (!token) {
      setError('Invalid reset link');
      return;
    }

    try {
      await axiosInstance.post('/api/reset-password', {
        token,
        new_password: passwords.newPassword
      });
      
      // Show success message and redirect to login
      setTimeout(() => navigate('/login'), 3000);
    } catch (error) {
      setError(error.response?.data?.detail || 'Password reset failed');
    }
  };

  const handleChange = (e) => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Paper sx={{ p: 4, maxWidth: 400, mx: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        {t('auth.actions.resetPassword')}
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          required
          type="password"
          name="newPassword"
          label={t('auth.fields.newPassword')}
          value={passwords.newPassword}
          onChange={handleChange}
          margin="normal"
        />
        <TextField
          fullWidth
          required
          type="password"
          name="confirmPassword"
          label={t('auth.fields.confirmNewPassword')}
          value={passwords.confirmPassword}
          onChange={handleChange}
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3 }}
        >
          {t('auth.actions.resetPassword')}
        </Button>
      </Box>
    </Paper>
  );
};

export default ResetPassword; 